import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/sofia-pro.css";
import "./assets/css/style.css";

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

reportWebVitals();
