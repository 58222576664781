import React from "react";
function Home() {
  return (
    <div className="row center">
      <div className="col-sm-12">
      <h2 id="teemu">Teemu</h2>
      <h4>PGP Public Key Fingerprint</h4>
      <p>24ee 1ba9 6b82 d2b2 e5f5 05b9 9a42 c42a 8dcc</p>
      <a
        target="_blank"
        type="button"
        className="btn btn-primary btn-primary-custom"
        href="https://storage.googleapis.com/commonsensefm/publickey.teemuairamo%40protonmail.com-cb7924ee1ba96b82d2b2e5f505b99a42c42a8dcc.asc"
      >
        Public Key
      </a>
      <h3 id="email" className="mt-2">Email</h3>
      <p>
        <a> teemuairamo [@] protonmail.com</a>
      </p>
    </div>
    </div>
  );
}

export default Home;
