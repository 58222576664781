import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Home from "../components/home/home";
import Landing from "../components/Landing/Landing";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Landing>
          <Route exact path="/" component={Home} />         
        </Landing>
      </Switch>
    </Router>
  );
};
export default Routes;
