import React from "react";

function Landing({ children }) {
  return (
    <>
      <div className="container-fluid">{children}</div>
    </>
  );
}

export default Landing;
